import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub, faLinkedin, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';

const Footer = () => {
  const [serverStatus, setServerStatus] = useState('loading');

  useEffect(() => {
    const fetchServerStatus = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/health`);
        if (response.ok) {
          setServerStatus('online');
        } else {
          setServerStatus('offline');
        }
      } catch (error) {
        console.error('Error fetching server status:', error);
        setServerStatus('offline');
      }
    };

    fetchServerStatus();
  }, []);

  const serverIcon = serverStatus === 'online' ? faCheckCircle : faTimesCircle;
  const serverIconColor = serverStatus === 'online' ? 'text-green-500' : 'text-red-500';
  const serverStatusText = serverStatus === 'online' ? 'Online' : 'Offline';

  return (
    <footer className="text-black py-4 mt-8 w-full px-4 border-t border-dashed border-gray-600 relative">
      <div className="container mx-auto flex flex-col items-center text-center sm:flex-row sm:items-center justify-center">
        <div className="flex items-center gap-2 mb-4 sm:mb-0 sm:mr-auto">
          <FontAwesomeIcon
            icon={serverIcon}
            className={`${serverIconColor} text-sm`}
          />
          <p className="text-sm font-semibold">Server Status: {serverStatusText}</p>
        </div>
        <p className="flex-grow mb-4 sm:mb-0 text-gray-700 font-semibold text-center">
          <a href="https://www.albertolinde.com" target="_blank" rel="noopener noreferrer" className="hover:underline">Developed by Alberto Linde</a>
        </p>
        <div className="flex gap-4">
          <a href="https://github.com/AlbertoLinde" target="_blank" rel="noopener noreferrer" className="hover:text-gray-300 transform hover:scale-125 transition-transform duration-300">
            <FontAwesomeIcon icon={faGithub} className="text-black text-lg" />
          </a>
          <a href="https://www.linkedin.com/in/albertolinde" target="_blank" rel="noopener noreferrer" className="hover:text-blue-600 transform hover:scale-125 transition-transform duration-300">
            <FontAwesomeIcon icon={faLinkedin} className="text-black text-lg" />
          </a>
          <a href="https://twitter.com/AlberALinde" target="_blank" rel="noopener noreferrer" className="hover:text-blue-400 transform hover:scale-125 transition-transform duration-300">
            <FontAwesomeIcon icon={faTwitter} className="text-black text-lg" />
          </a>
          <a href="mailto:abreulindealberto@gmail.com" className="hover:text-gray-500 transform hover:scale-125 transition-transform duration-300">
            <FontAwesomeIcon icon={faEnvelope} className="text-black text-lg" />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
