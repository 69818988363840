import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faRedo, faTimes, faWindowClose, faEdit } from '@fortawesome/free-solid-svg-icons';

const Modal = ({ isOpen, onClose, onAddOption, options, onResetOptions, onRemoveOption }) => {
  const [newOption, setNewOption] = useState('');
  const [shakeEffect, setShakeEffect] = useState(false);
  const [editingOption, setEditingOption] = useState({ index: -1, value: '' });

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Escape') {
        onClose();
        setEditingOption({ index: -1, value: '' });
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [onClose]);

  const triggerShake = () => {
    setShakeEffect(true);
    setTimeout(() => setShakeEffect(false), 820);
  };

  const handleAddOptionInternal = () => {
    if (newOption.trim() !== '') {
      onAddOption(newOption);
      setNewOption('');
    } else {
      triggerShake();
    }
  };

  const handleEditOption = (index) => {
    setEditingOption({ index, value: options[index].option });
  };

  const handleSaveEditedOption = () => {
    if (editingOption.value.trim() !== '') {
      const updatedOptions = options.map((option, idx) =>
        idx === editingOption.index ? { ...option, option: editingOption.value } : option);
      onResetOptions(updatedOptions);
      setEditingOption({ index: -1, value: '' });
    }
  };

  const handleRemoveOptionInternal = (index) => {
    if (options.length > 1) {
      onRemoveOption(index);
    } else {
      triggerShake();
    }
  };

  return (
    <div className={`fixed z-10 inset-0 overflow-y-auto ${isOpen ? 'block' : 'hidden'} ${shakeEffect ? 'animate-shake' : ''}`} aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div className="flex items-center justify-center min-h-screen">
        <div className="bg-white w-full max-w-md p-6 rounded-lg shadow-xl">
          <div className="mb-4">
            <label htmlFor="option" className="block text-gray-700 font-bold mb-2">Option:</label>
            <input type="text" id="option" className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" placeholder="Enter option" value={newOption} onChange={(e) => setNewOption(e.target.value)} onKeyPress={(e) => e.key === 'Enter' && handleAddOptionInternal()} />
          </div>
          <div className="flex justify-around mb-4 space-x-2">
            <button className="flex-1 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded flex justify-center items-center gap-2" onClick={handleAddOptionInternal}>
              <FontAwesomeIcon icon={faPlus} className="sm:hidden"/>
              <span className="hidden sm:block">Add</span>
            </button>
            <button className="flex-1 bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded flex justify-center items-center gap-2" onClick={() => onResetOptions([])}>
              <FontAwesomeIcon icon={faRedo} className="sm:hidden"/>
              <span className="hidden sm:block">Reset</span>
            </button>
            <button className="flex-1 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded flex justify-center items-center gap-2" onClick={onClose}>
              <FontAwesomeIcon icon={faWindowClose} className="sm:hidden"/>
              <span className="hidden sm:block">Close</span>
            </button>
          </div>
          <div className="mb-4 overflow-y-auto" style={{ maxHeight: '150px' }}>
            {options.map((option, index) => (
              <div key={index} className="flex justify-between items-center my-2">
                {editingOption.index === index ? (
                  <input
                    type="text"
                    value={editingOption.value}
                    onChange={(e) => setEditingOption({ ...editingOption, value: e.target.value })}
                    onBlur={handleSaveEditedOption}
                    onKeyPress={(e) => e.key === 'Enter' && handleSaveEditedOption()}
                    className="shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    autoFocus
                  />
                ) : (
                  <span onClick={() => handleEditOption(index)}>{option.option}</span>
                )}
                <div className="flex items-center">
                  <button className="mr-2 text-blue-500 hover:text-blue-700" onClick={() => handleEditOption(index)}>
                    <FontAwesomeIcon icon={faEdit} />
                  </button>
                  <button className="text-red-500 hover:text-red-700" onClick={() => handleRemoveOptionInternal(index)}>
                    <FontAwesomeIcon icon={faTimes} />
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
