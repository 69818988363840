import React, { useEffect, useState } from 'react';
import Typical from 'react-typical';
import RouletteWheel from './components/RouletteWheel';
import Leaderboard from './components/Leaderboard';
import Footer from './components/Footer';
import About from './components/About';
import './App.css';

function App() {
  const [leaderboardData, setLeaderboardData] = useState([]);

  const fetchLeaderboardData = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/foods`);
      const data = await response.json();
      setLeaderboardData(data.data);
    } catch (error) {
      console.error('Error fetching leaderboard data:', error);
    }
  };

  useEffect(() => {
    fetchLeaderboardData();
  }, []);

  return (
    <div className="flex flex-col min-h-screen bg-gray-100">
      <div className="flex-grow flex flex-col justify-center items-center">
        <div className="w-full text-center mb-8 mt-12">
          <h1 className="text-4xl font-bold">
            What shall we eat today?{ ' ' }
            <Typical
              loop={Infinity}
              wrapper="b"
              steps={[
                'Pizza 🍕', 2000,
                'Italian 🍝', 2000,
                'Indian 🍛', 2000,
                'Sushi 🍣', 2000,
              ]}
            />
          </h1>
        </div>

        <About/>

        <div className="w-3/4 max-w-4xl mx-auto flex flex-col md:flex-row gap-4 md:gap-14 h-full">
          <div className="w-full md:w-3/5 h-full">
            <RouletteWheel />
          </div>
          <div className="w-full md:w-2/5 h-full overflow-auto">
            <Leaderboard data={leaderboardData} onReloadData={fetchLeaderboardData} />
          </div>
        </div>
      </div>

      <Footer/>
    </div>
  );
}

export default App;
