import React from 'react';

function About() {
  return (
    <div className="my-8 mx-4 p-4 bg-blue-100 rounded-lg shadow-lg max-w-4xl text-center">
      <h2 className="text-xl font-bold mb-2">Why did I create this?</h2>
      <p className="text-gray-700">
        Ever in a fix with your code buddies about what to eat? Yep, we've all been there. We'd spin wheels, but never
        really followed through. 🍕🍜🍣 So, I thought, what if there's a way to really commit to the wheel's choice?
      </p>
      <p className="mt-2">
        That's how this was born! It's simple: <strong>"Be honest" - if you spin it, you eat it!</strong> Let's make lunch decisions fun
        and binding. 😄 And hey, I'm curious - does the wheel's choice match what people from different countries
        actually prefer? Let's find out together!
      </p>
    </div>
  );
}

export default About;
