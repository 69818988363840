import React, { useState, useMemo } from 'react';
import { Wheel } from 'react-custom-roulette';
import Modal from './commons/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faSyncAlt } from '@fortawesome/free-solid-svg-icons';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const RouletteWheel = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [mustSpin, setMustSpin] = useState(false);
  const [prizeNumber, setPrizeNumber] = useState(0);
  const defaultOptions = [
    { option: 'Pizza 🍕', color: '#FF5733' },
    { option: 'Italian 🍝', color: '#33FF6E' },
    { option: 'Indian 🍛', color: '#3366FF' },
    { option: 'Sushi 🍣', color: '#FF33CC' },
    { option: 'Burgers 🍔', color: '#FF851B' },
    { option: 'Chinese 🥡', color: '#FFD700' },
    { option: 'Mexican 🌮', color: '#FF6347' },
    { option: 'Thai 🍜', color: '#D2691E' },
    { option: 'Korean 🍲', color: '#A0522D' },
    { option: 'French 🥖', color: '#2E8B57' },
    { option: 'Greek 🥙', color: '#8A2BE2' },
    { option: 'Spanish 🥘', color: '#5F9EA0' },
    { option: 'Lebanese 🍽️', color: '#1E90FF' },
    { option: 'Japanese 🍱', color: '#FF69B4' },
    { option: 'Vegan 🥦', color: '#98FB98' },
    { option: 'Peruvian 🍲', color: '#FF4500' },
    { option: 'Turkish 🍗', color: '#DEB887' },
    { option: 'Filipino 🍚', color: '#FFB6C1' },
  ];
  const [options, setOptions] = useState([...defaultOptions]);

  const handleAddOption = (newOption) => {
    const newColor = '#' + Math.floor(Math.random() * 16777215).toString(16);
    setOptions([...options, { option: newOption, color: newColor }]);
  };

  const handleResetOptions = () => {
    setOptions([...defaultOptions]);
  };

  const handleRemoveOption = (indexToRemove) => {
    setOptions(prevOptions => prevOptions.filter((_, index) => index !== indexToRemove));
  };

  const sendSelectionToBackend = async (selectedOption) => {
    let foodToSend = selectedOption;
    if (!defaultOptions.some(option => option.option === selectedOption)) {
      foodToSend = 'Other';
    }

    try {
      const response = await fetch(`${BACKEND_URL}/api/food`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          food: foodToSend,
          language: navigator.language,
        }),
      });
      const data = await response.json();
      console.log(data);
    } catch (error) {
      console.error('Error:', error);
    }
  };


  const fontSettings = useMemo(() => {
    const baseFontSize = 18;
    const baseFontWeight = '700';
    let adjustedFontSize = options.length > 10 ? baseFontSize - 2 : baseFontSize;
    let adjustedFontWeight = options.length > 10 ? '400' : baseFontWeight;
    return {
      fontFamily: 'Nunito',
      fontSize: adjustedFontSize,
      fontWeight: adjustedFontWeight,
    };
  }, [options.length]);

  const handleSpinClick = () => {
    const newPrizeNumber = Math.floor(Math.random() * options.length);
    setPrizeNumber(newPrizeNumber);
    setMustSpin(true);
  };

  return (
    <div className="flex flex-col items-center">
      <Wheel
        mustStartSpinning={mustSpin}
        prizeNumber={prizeNumber}
        data={options.map(option => ({
          option: option.option,
          style: { backgroundColor: option.color, textColor: '#ffffff' },
        }))}
        onStopSpinning={async () => {
          setMustSpin(false);
          await sendSelectionToBackend(options[prizeNumber].option);
        }}
        fontFamily={fontSettings.fontFamily}
        fontSize={fontSettings.fontSize}
        fontWeight={fontSettings.fontWeight.toString()}
      />
      <div className="mt-4 w-full">
        <button
          className="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out flex justify-center items-center"
          onClick={handleSpinClick}
        >
          <span className="hidden md:block">SPIN!</span>
          <span className="block md:hidden">🎰</span>
        </button>
      </div>
      <div className="w-full flex justify-between space-x-2 mt-2">
        <button
          className="w-1/2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          onClick={() => setIsModalOpen(true)}
        >
          <FontAwesomeIcon icon={faPlus} className="md:hidden" />
          <span className="hidden md:block">Add / Remove Options</span>
        </button>
        <button
          className="w-1/2 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
          onClick={handleResetOptions}
        >
          <FontAwesomeIcon icon={faSyncAlt} className="md:hidden" />
          <span className="hidden md:block">Reset</span>
        </button>
      </div>
      {isModalOpen && (
        <Modal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          onAddOption={handleAddOption}
          options={options}
          onResetOptions={handleResetOptions}
          onRemoveOption={handleRemoveOption}
        />
      )}
    </div>
  );
};

export default RouletteWheel;
