import React, { useState, useEffect } from 'react';

const Leaderboard = ({ data, onReloadData }) => {
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [sortedData, setSortedData] = useState([]);

  useEffect(() => {
    if (data && data.length > 0) {
      const sorted = data.slice().sort((a, b) => b.vote_count - a.vote_count);
      setSortedData(sorted);
    }
  }, [data]);

  const handleRowClick = (country) => {
    setSelectedCountry(selectedCountry === country ? null : country);
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const filteredData = selectedCountry
    ? sortedData.filter((row) => row.country === selectedCountry)
    : sortedData.reduce((acc, current) => {
      if (!acc.find((item) => item.country === current.country)) {
        acc.push(current);
      }
      return acc;
    }, []);

  let countryTitle = null;
  if (selectedCountry) {
    countryTitle = <strong>Leaderboard - {capitalizeFirstLetter(selectedCountry)}</strong>;
  }

  return (
    <div className="overflow-x-auto relative">
      {countryTitle && (
        <div className="bg-gray-200 px-6 py-4 mb-4">
          {countryTitle}
        </div>
      )}
      {sortedData.length > 0 ? (
        <table className="w-full text-sm text-gray-500">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50">
          <tr>
            <th className="px-6 py-3">Country</th>
            <th className="px-6 py-3 text-center">Food</th>
            <th className="px-6 py-3 text-center">Count</th>
          </tr>
          </thead>
          <tbody>
          {filteredData.map((row, index) => (
            <tr
              key={index}
              className={`bg-white transform transition-all duration-200 hover:scale-105 hover:-translate-y-1 hover:shadow-lg hover:z-10 hover:bg-gray-100 hover:font-bold ${
                selectedCountry === row.country ? 'selected-row' : ''
              }`}
              onClick={() => handleRowClick(row.country)}
            >
              <td className="px-6 py-4">{capitalizeFirstLetter(row.country)}</td>
              <td className="px-6 py-4 text-center">{capitalizeFirstLetter(row.food)}</td>
              <td className="px-6 py-4 text-center">{row.vote_count}</td>
            </tr>
          ))}
          </tbody>
        </table>
      ) : (
        <div className="text-center my-4">
          <p>No data available.</p>
          <button
            onClick={onReloadData}
            className="mt-2 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700 transition-colors"
          >
            Reload Data
          </button>
        </div>
      )}
    </div>
  );
};

export default Leaderboard;
